import { Box, Button, Card, CircularProgress, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getHeaders } from "auth/authService";
import { bool } from "aws-sdk/clients/signer";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createEmbeddingContext, EmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import Grid from "@mui/material/Grid";
import { getProperties } from "lib/util";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

type embedUrlResponseType = {
  EmbedUrl: string,
  Status: number,
}



export const getUrl = async (dashboardId: string): Promise<string> => {

  const headers = await getHeaders();

  try {
    const fetchedPost: any = await fetch(`${process.env.REACT_APP_API_URL}/analytics/getEmbedUrl/${dashboardId}`, {
      mode: 'cors',
      method: 'get',
      headers: headers,
    });
    
    const data: embedUrlResponseType = await fetchedPost.json();
    console.log("embed url ", data);
   
    return data.EmbedUrl;
  } catch (error) {
    console.error("Error:", error);
    return "Error getting analytics url";
  }
};

const ShowAnalytics = (): JSX.Element => {

  const [analyticsUrl, setAnalyticsUrl] = useState<string>('');
  const { dashboardId } = useParams();
  console.log("🚀 ~ ShowAnalytics ~ dashboardId:", dashboardId);
  const [loading, setLoading] = useState<boolean>(true);
  console.log("🚀 ~ ShowAnalytics ~ loading:", loading);
  const [propertyId, setPropertyId] = useState<string>('');
  const [propertyData, setPropertyData] = useState<any>([])
  const [embeddedDashboardExperience, setEmbeddedDashboardExperience] = useState<any>(null)

  const previousMonday = dayjs().subtract(1, 'week').startOf('week').add(1, 'day');
  const previousSunday = dayjs().subtract(1, 'week').endOf('week').add(7, 'day');
  const [startDate , setStartDate] = useState<any>(previousMonday)
  const [endDate , setEndDate] = useState<any>(previousSunday)
  const [dashboardData, setDashboardData] = useState<any>([])
  const [period, setPeriod] = useState<string>('Week')

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      //Load Property Select
      let initPropertyId = '';
      const headers = await getHeaders();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/properties/${sessionStorage.getItem("company_id")}`, {
        mode: 'cors',
        method: 'get', 
        headers: headers,
      })
      .then((response) => response.json())
      .then((data) => {
        setPropertyData(data)
        setPropertyId(data[0].name)
        initPropertyId = data[0].name;
      })
      .catch((error) => {
        console.error("Error:", error)
      })

      setDashboardData(dashboardId);
      const result = await getUrl(dashboardId);
      if (result) {
        console.log("result: ", result);
        setAnalyticsUrl(result.toString());

        const frameOptions = {
          url: result.toString(),
          container: '#experience-container',
          resizeHeightOnSizeChangedEvent: true,
          onChange: (changeEvent: { eventName: any; }, metadata: any) => {
              switch (changeEvent.eventName) {
                  case 'FRAME_MOUNTED': {
                      console.log("Do something when the experience frame is mounted.");
                      break;
                  }
                  case 'FRAME_LOADED': {
                      console.log("Do something when the experience frame is loaded.");
                      break;
                  }
              }
          },
      };

      const contentOptions = {
        parameters: [
          {
              Name: 'granularity',
              Values: ['Week'],
          },
          {   
            Name: 'propertyName', 
            Values: [initPropertyId] 
          },
          {
            Name: 'startDate',
            Values: startDate
        },
        {
          Name: 'endDate',
          Values: endDate
        },
        {
          Name: 'granularity',
          Values: period
        },
      ],
 
      };

      const getEmbeddingContext = async (): Promise<EmbeddingContext> => {
          const embeddingContext = await createEmbeddingContext();
          return embeddingContext;
      }

      let embeddingContext: EmbeddingContext;

      getEmbeddingContext().then(context => {
          embeddingContext = context;

          const {
              embedDashboard,
           } = embeddingContext;

       
          // Embedding a dashboard experience
          embedDashboard(frameOptions, contentOptions).then(embeddedDashboardExperience => {
              setEmbeddedDashboardExperience(embeddedDashboardExperience);
          });
      });
      }

      setLoading(false);
    };
    fetchData();
}, []);

const propertySelect = (
  <FormControl sx={{ m: 1, minWidth: 120, minHeight: 50 }}>
    <InputLabel id="property-label">Property</InputLabel>
    <Select
      sx={{ width: '100%', minWidth: 180, minHeight: 40, backgroundColor: 'white' }}
      labelId="property-label"
      id="property-select-helper"
      label="Property"
      value={propertyId}
      onChange={handlePropertyChange}
    >
      {propertyData && propertyData.map((item: any,key: number)=> (
        <MenuItem value={item.name}>{item.name}</MenuItem>
      ))} 
    </Select>
  </FormControl>
)

const dashBoardSelect = (

  <FormControl sx={{ m: 1, minWidth: 120, minHeight: 50 }}>
    <InputLabel id="dashboard-label">Dashboard</InputLabel>
    <Select
      sx={{ width: '100%', minWidth: 180,minHeight: 40 }}
      labelId="dashboard-label"
      id="dashboard-select-helper"
      label="Dashboard"
      value={dashboardData}
      onChange={handleDashboardChange}
    >
      {JSON.parse(sessionStorage.getItem("dashboards")).map((item: any,key: number)=> (
        <MenuItem value={item.id}>{item.name}</MenuItem>
      ))} 
    </Select>
  </FormControl>
)



function handlePropertyChange(event: SelectChangeEvent<any>): void {
  setPropertyId(event.target.value);
  embeddedDashboardExperience.setParameters([
    {
        Name: 'propertyName',
        Values: event.target.value
    }
  ]);
}

function handleDashboardChange(event: SelectChangeEvent<any>): void {
  setDashboardData(event.target.value);

  const dashboardId = event.target.value;
  const formattedStartDate = dayjs(startDate).format('YYYY/MM/DD');
  const formattedEndDate = dayjs(endDate).format('YYYY/MM/DD');
  const options = {
      parameters: [
          {
              Name: 'propertyName',
              Values: propertyId,
          },
          {
              Name: 'granularity',
              Values: period,
          },
          {
              Name: 'startDate',
              Values: formattedStartDate
          },
          {
              Name: 'endDate',
              Values: formattedEndDate
          },
      ]
  };
  embeddedDashboardExperience.navigateToDashboard(dashboardId, options);
}

function resetDates(){
  const formattedStartDate = dayjs(startDate).format('YYYY/MM/DD');
  const formattedEndDate = dayjs(endDate).format('YYYY/MM/DD');
  // console.log("startDate: ", formattedStartDate);
  // console.log("endDate: ", formattedEndDate);
  embeddedDashboardExperience.setParameters([
    {
        Name: 'startDate',
        Values: formattedStartDate
    },
    {
      Name: 'endDate',
      Values: formattedEndDate
    },
  ]);
}

function handlePeriodChange(event: SelectChangeEvent<any>): void {
  setPeriod(event.target.value);
  embeddedDashboardExperience.setParameters([
    {
        Name: 'granularity',
        Values: event.target.value
    }
  ]);
}

const periodSelect = (
  <FormControl sx={{ m: 1, minWidth: 120, minHeight: 50 }}>
    <InputLabel id="period-label">Period</InputLabel>
    <Select
      sx={{ width: '100%', minWidth: 180,minHeight: 40 }}
      labelId="period-label"
      id="perio-select-helper"
      value={period}
      label="Compare To"
      onChange={handlePeriodChange}
      autoWidth
    >
      <MenuItem value='Day'>Day</MenuItem>
      <MenuItem value='Week'>Week</MenuItem>
      <MenuItem value='Month'>Month</MenuItem>
      <MenuItem value='Quarter'>Quarter</MenuItem>
    </Select>
  </FormControl>
)

  return (
    <Grid container >

      <Grid item lg={12}>
        <Grid container>
          <Grid item lg={6} py={1}>
              <Card>
                <Grid container sx={{ height: 75 }}>
                  <Grid item lg={4} >
                    <Box p={1}>
                    {propertySelect}
                    </Box>
                  </Grid>
                  <Grid item lg={4} >
                    <Box p={1}>
                    {dashBoardSelect}
                    </Box>
                  </Grid>
                  <Grid item lg={4} >
                    <Box p={1}>
                    {periodSelect}
                    </Box>
                  </Grid>
                </Grid>
              </Card>
          </Grid>
          <Grid item lg={6} p={1}>
            <Card>
                <Grid container sx={{ height: 75 }}>
                  <Grid item lg={4} >
                    <Box p={2}>
                      <DatePicker 
                        format="DD-MM-YYYY"
                        label="From"
                        value={dayjs(startDate)}
                        onChange={(newValue) => setStartDate(newValue)}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box p={2}>
                      <DatePicker 
                        format="DD-MM-YYYY"   
                        label="To"
                        value={dayjs(endDate)}
                        onChange={(newValue) => setEndDate(newValue)}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={4} >
                    <Box p={2}>
                      <Button 
                        onClick={() => resetDates()}
                        sx={{ backgroundColor: 'cornflowerblue', color: 'ButtonText' }}
                        variant="contained" 
                        >
                          Apply Dates
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
          </Grid>
        </Grid>
      </Grid>


      
      <Grid item lg={12}>
          <div id="experience-container"></div>

      </Grid>
    </Grid>
  );
};

export default ShowAnalytics;
